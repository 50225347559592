import React, { useEffect, useRef, useState } from "react";
import { InputField } from "../../components/InputField";
import { TypePrimaryState } from "../../components/TypePrimaryState";
import "./style.css";
import { useVGSCardForm } from "../../hooks/vgs";
import {
  beneficiaryTransfer,
  rawTransfer,
  validateSession,
} from "../../controllers/transfer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteBeneficiary,
  getBeneficiaries,
  getConfig,
  getProfile,
  saveBeneficiary,
} from "../../controllers/setup";
import { DescriptionField } from "../../components/DiscriptionField";
import { Beneficiaries, BeneficiaryList } from "../../components/Beneficiaries";
import { DeductionAuthorizationModal } from "../../components/DeductionAuthorizationModal";
import { FinLiteracyModal } from "../../components/FinLiteracyModal";
import { SuccessModal } from "../../components/SuccessModal";
import { CurrencyInputField } from "../../components/InputField";
import { ErrorField } from "../../components/ErrorField";

const FIN_LITERACY_URL = process.env.FIN_LITERACY_URL;
const VAULT_ID = process.env.VAULT_ID || "tntww2dnfb0";
const ENV = process.env.ENV || "sandbox";
const ACC_NO_ID = "cc-number";
const EXP_ID = "cc-expiration-date";
const CURRENCY = "USD";

export const Frame = () => {
  const [params] = useSearchParams();
  const tokenParam = params.get("token");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [run, setRun] = useState(false);
  const [beneficiary, setBeneficiary] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [amount, setAmount] = useState({
    display: "",
    value: 0,
  });
  const [minTransfer, setMinTransfer] = useState(Number.POSITIVE_INFINITY);
  const [maxTransfer, setMaxTransfer] = useState(Number.NEGATIVE_INFINITY);
  const [transferFee, setTransferFee] = useState(0);
  const [token, setToken] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [beneficiaryOpen, setBeneficiaryOpen] = useState(false);
  const [showTermsModal, setshowTermsModal] = useState(false);
  const [showFinLiteracy, setShowFinLiteracy] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [disableSaveBeneficiary, setDisableSaveBeneficiary] = useState(false);
  const [cardDetails, setCardDetials] = useState({
    cardNumber: "",
    expiryDate: "",
  });
  const [profile, setProfile] = useState({
    organization: "",
    balance: 0,
  });
  const [beneficiaryLoading, setBeneficiaryLoading] = useState(false);

  const [form, onSubmit, loading, isValid] = useVGSCardForm({
    vaultId: VAULT_ID,
    env: ENV,
    accNoId: ACC_NO_ID,
    expId: EXP_ID,
    onLoadFail: (e) => {
      console.error(e);
      toast.error("An unexpected error occured, please retry");
    },
    run,
  });

  useEffect(() => {
    const adjustedUserBalance = profile.balance - transferFee;
    if (
      amount &&
      amount.value >= minTransfer &&
      amount.value <= Math.min(maxTransfer, adjustedUserBalance) &&
      adjustedUserBalance >= minTransfer
    )
      setIsAmountValid(true);
    else setIsAmountValid(false);
  }, [amount, profile.balance]);

  useEffect(() => {
    document.title = "Instant transfer (Card)";
    if (tokenParam) {
      validateSession(tokenParam, (err) => {
        if (err) {
          navigate(pathname);
          toast.dismiss();
          return toast.error("Session expired, please try again");
        }
        setToken(tokenParam);
        navigate(pathname);
        getConfig((err, data) => {
          if (err) {
            toast.dismiss();
            return toast.error(err.message);
          }
          try {
            setMinTransfer(data?.data?.minWithdrawalCents[CURRENCY] / 100 || 5);
            setMaxTransfer(
              data?.data?.maxWithdrawalCents[CURRENCY] / 100 || 200
            );
            setTransferFee(
              data?.data?.instantTransferFeeCents[CURRENCY] / 100 || 4.95
            );
          } catch (error) {
            console.error(error, data);
          }
        });
        setRun(true);
      });
    } else {
      toast.dismiss();
      toast.error("Session expired, please try again");
    }
  }, []);

  const loadBeneficiaries = () => {
    if (token) {
      getBeneficiaries(token, (err, data) => {
        if (err) {
          toast.dismiss();
          return toast.error(err.message);
        }
        setBeneficiaries(data.data || []);
      });
      getProfile(token, (err, data) => {
        if (err) {
          toast.dismiss();
          return toast.error(err.message);
        }
        setProfile({
          organization: data.data?.organization?.name || "",
          balance: data.data?.balance?.valueCents / 100,
        });
      });
    }
  };

  useEffect(() => {
    loadBeneficiaries();
  }, [token, disableSaveBeneficiary]);

  const onSubmitForm = () => {
    const postTransfer = (err, data) => {
      toast.dismiss();
      if (err) {
        setBtnLoading(false);
        return toast.error(err.message);
      }
      if (data.data?.showFinLiteracyPopup) {
        setShowFinLiteracy(true);
      } else {
        setShowSuccessModal(true);
      }
    };

    setBtnLoading(true);
    setshowTermsModal(false);
    if (beneficiary)
      beneficiaryTransfer(amount.value, beneficiary.id, token, postTransfer);
    else
      onSubmit(async (err, status, data) => {
        if (err) {
          console.error(err);
          toast.dismiss();
          setBtnLoading(false);
          return toast.error("Please check the card details and try again");
        }
        const cardDetails = JSON.parse(data.data);
        if (!/^\d+$/.test(cardDetails.disbursementNumber)) {
          toast.dismiss();
          setBtnLoading(false);
          return toast.error("Invalid card number");
        }

        let [month, year] = cardDetails.card_expirationDate.split("/");
        month = Number(month);
        year = Number(year);
        if (year < 100) {
          year = Math.floor(new Date().getFullYear() / 100) * 100 + year;
        }
        const expiryDate = new Date(year, month, 0).toISOString().slice(0, 7);
        const cardNumber = cardDetails.disbursementNumber;

        setCardDetials({ cardNumber, expiryDate });
        rawTransfer(amount.value, cardNumber, expiryDate, token, postTransfer);
        setBtnLoading(false);
      });
  };

  const navigateToFinLiteracy = () => {
    if (!FIN_LITERACY_URL) {
      toast.dismiss();
      return toast.error(
        "Could not locate the Financial Literacy experience. Try again"
      );
    }
    window.open(FIN_LITERACY_URL, "_blank");
    closeFinLiteracy(false);
  };

  const closeFinLiteracy = () => {
    setShowFinLiteracy(false);
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    form.reset();
    setShowSuccessModal(false);
    setDisableSaveBeneficiary(false);
    setAmount({
      display: "",
      value: 0,
    });
    setBeneficiary(null);
    setBtnLoading(false);
  };

  const removeBeneficiary = (id) => {
    deleteBeneficiary(token, id, (err) => {
      toast.dismiss();
      if (err) return toast.error(err.message);
      let newBeneficiaries = [...beneficiaries];
      newBeneficiaries = newBeneficiaries.filter((b) => b.id != id);
      setBeneficiaries(() => newBeneficiaries);
      toast.success("The card has been deleted");
    });
  };

  const saveBeneficiaryInfo = () => {
    if (beneficiaryLoading || disableSaveBeneficiary || beneficiary) return;
    setBeneficiaryLoading(true);
    const data = {
      name: beneficiaryName,
      ...cardDetails,
    };
    saveBeneficiary(token, data, (err) => {
      toast.dismiss();
      setBeneficiaryLoading(false);
      if (err) {
        return toast.error(err.message);
      }
      loadBeneficiaries();
      setDisableSaveBeneficiary(true);
      toast.success("The card has been saved");
    });
  };

  return (
    <div className="frame">
      <ToastContainer
        position="top-center"
        autoClose={false}
        theme="light"
        toastStyle={{
          width: "325px",
          borderRadius: "5px",
          fontFamily: "Poppins, Helvetica",
          border: "1px solid rgba(219, 219, 219, 1)",
          fontSize: "14px",
          fontWeight: "400",
          color: "rgba(95, 95, 95, 1)",
        }}
      />
      {run && (
        <>
          {loading && (
            <div className="overlay">
              <span className="loader"></span>
            </div>
          )}
          <div
            className={
              "group-wrapper" + (beneficiaryOpen ? " d-flex" : " d-none")
            }
          >
            <BeneficiaryList
              beneficiaries={beneficiaries}
              setBeneficiaryOpen={setBeneficiaryOpen}
              setBeneficiary={setBeneficiary}
              removeBeneficiary={removeBeneficiary}
            />
          </div>
          <div
            className={
              "group-wrapper" + (beneficiaryOpen ? " d-none" : " d-flex")
            }
          >
            <div className="div-2">
              <CurrencyInputField
                label="Transfer Amount"
                baseInputBaseInputClassName="input-field-2"
                baseInputPlaceholder="$0.00"
                className="input-field-instance"
                divClassName="design-component-instance-node"
                value={amount.display}
                setValue={(v) =>
                  setAmount({ display: v.display, value: v.value })
                }
                isValid={isAmountValid}
                showInfo={false}
              />
              {profile.balance < minTransfer + transferFee ? (
                <ErrorField
                  message={`You have insufficient funds to make a transfer, please wait until tomorrow.`}
                />
              ) : (
                !isAmountValid &&
                amount &&
                amount.value > 0 &&
                /* Minimum withdrawal amount - $5 */
                ((amount.value < minTransfer && (
                  <ErrorField
                    message={`Minimum withdrawal amount is $${parseFloat(
                      minTransfer
                    ).toFixed(2)}`}
                  />
                )) ||
                  /* Maximum withdrawal amount - $200 or balance - transaction fee */
                  ((amount.value > maxTransfer ||
                    amount.value > profile.balance - transferFee) && (
                    <ErrorField
                      message={`Maximum withdrawal amount is $${parseFloat(
                        Math.min(maxTransfer, profile.balance - transferFee)
                      ).toFixed(2)}`}
                    />
                  )))
              )}
              {isAmountValid && (
                <DescriptionField
                  amount={amount}
                  transactionFee={transferFee}
                />
              )}
              <Beneficiaries
                beneficiary={beneficiary}
                setBeneficiary={setBeneficiary}
                beneficiaries={beneficiaries.slice(0, 6)}
                setBeneficiaryOpen={setBeneficiaryOpen}
              />
              <div className={"div-3" + (beneficiary ? " d-none" : "")}>
                <InputField
                  baseInputBaseInputClassName="input-field-2"
                  baseInputFrameClassName="input-field-4"
                  baseInputPlaceholder=" enter account number"
                  baseInputValidation={false}
                  className="input-field-instance"
                  divClassName="design-component-instance-node"
                  label="Debit Card Number"
                  showInfo={false}
                  state="normal"
                  baseInputId={ACC_NO_ID}
                />
                <InputField
                  baseInputBaseInputClassName="input-field-2"
                  baseInputEyeFillHasOutlined={false}
                  baseInputPlaceholder="YYYY/MM/DD"
                  baseInputValidation={false}
                  className="input-field-instance"
                  divClassName="design-component-instance-node"
                  label="Expiration Date"
                  showInfo={false}
                  state="normal"
                  baseInputId={EXP_ID}
                />
              </div>
            </div>
            <TypePrimaryState
              baseButtonDivClassName="type-primary-state-normal-outline-false-link-false"
              baseButtonShowLeftIcon={false}
              baseButtonShowRightIcon={false}
              baseButtonText="Withdraw"
              className="button"
              onClick={() => setshowTermsModal(true)}
              disabled={
                !((isValid || beneficiary) && isAmountValid) || btnLoading
              }
              loading={btnLoading}
            />
          </div>
        </>
      )}
      <DeductionAuthorizationModal
        show={(isValid || beneficiary) && isAmountValid && showTermsModal}
        loading={btnLoading}
        acceptFn={onSubmitForm}
        closeFn={() => setshowTermsModal(false)}
        profile={profile}
      />
      <FinLiteracyModal
        show={showFinLiteracy}
        acceptFn={navigateToFinLiteracy}
        closeFn={closeFinLiteracy}
      />
      <SuccessModal
        show={showSuccessModal}
        acceptFn={navigateToFinLiteracy}
        closeFn={closeSuccessModal}
        beneficiaryName={beneficiaryName}
        setBeneficiaryName={setBeneficiaryName}
        saveBeneficiary={saveBeneficiaryInfo}
        beneficiaryLoading={beneficiaryLoading}
        saveBeneficiaryTxt={
          disableSaveBeneficiary || beneficiary ? "Saved" : "Save"
        }
        amount={`$${amount.value}`}
        beneficary={beneficiary}
      />
    </div>
  );
};
