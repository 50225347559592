/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChevronLeft1 = ({ className }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3536 14.8536C11.1583 15.0488 10.8417 15.0488 10.6464 14.8536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645L10.6464 2.14645C10.8417 1.95118 11.1583 1.95118 11.3536 2.14645C11.5488 2.34171 11.5488 2.65829 11.3536 2.85355L5.70711 8.5L11.3536 14.1464C11.5488 14.3417 11.5488 14.6583 11.3536 14.8536Z" fill="#09132D" />
    </svg>
  );
};
