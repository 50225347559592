import React from "react";
import ReactDOMClient from "react-dom/client";
import { Frame } from "./screens/Frame";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  <Router>
    <Routes>
      {/* <Route path="/instant-transfer" Component={Frame} /> */}
      <Route path="/" Component={Frame} />
    </Routes>    
    {/* <Frame /> */}
  </Router>
);
