import React, { useRef, useEffect } from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { createNumberMask } from "text-mask-addons";
import { cashToFloat } from "../../utils/money"

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 4, // We don't want to let users input numbers greater than 9,999.99
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const CurrencyInputField = ({
  label = "Label",
  info = "Note",
  showLabel = true,
  showInfo = true,
  state,
  className,
  divClassName,
  baseInputBaseInputClassName,
  baseInputPlaceholder,
  baseInputId,
  value,
  setValue,
  isValid,
  rightComponent,
  maskOptions,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });
  const baseClassName = `div base-input-instance ${baseInputBaseInputClassName} ${value ? (isValid ? 'success' : 'error') : ''}`;

  return (
    <div className={`input-field ${className}`}>
      {showLabel && <div className={`text-wrapper ${state} ${divClassName}`}>{label}</div>}
      <div className="frame-3">
        <MaskedInput
          mask={currencyMask}
          id={baseInputId}
          className={baseClassName}
          placeholder={baseInputPlaceholder}
          value={value}
          onChange={(e) => setValue({display: e.target.value, value: cashToFloat(e.target.value)})}
          inputMode="numeric"
          {...inputProps}
          render={(ref, props) => <input ref={ref} {...props}/>}
        />
        {rightComponent}
        {showInfo && <div className="note">{info}</div>}
      </div>
    </div>
  );
};

CurrencyInputField.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  showLabel: PropTypes.bool,
  showInfo: PropTypes.bool,
  errorMessage: PropTypes.string,
  state: PropTypes.oneOf(["warning", "active", "success", "normal", "hover", "alert", "disabled"]),
  className: PropTypes.string,
  divClassName: PropTypes.string,
  baseInputBaseInputClassName: PropTypes.string,
  baseInputPlaceholder: PropTypes.string,
  baseInputId: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  isValid: PropTypes.bool,
  rightComponent: PropTypes.node,
  readOnly: PropTypes.bool,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.number,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
};