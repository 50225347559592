/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CheckCircle = ({ className }) => {
  return (
    <svg className={className} width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7462_7894)">
        <path d="M30.4998 3.38892C25.1377 3.38892 19.8961 4.97895 15.4377 7.95796C10.9793 10.937 7.50437 15.1711 5.45239 20.1251C3.40042 25.079 2.86353 30.5301 3.90962 35.7891C4.9557 41.0482 7.53779 45.8789 11.3293 49.6705C15.1209 53.462 19.9516 56.0441 25.2107 57.0902C30.4697 58.1363 35.9209 57.5994 40.8748 55.5474C45.8287 53.4955 50.0629 50.0206 53.0419 45.5622C56.0209 41.1038 57.6109 35.8621 57.6109 30.5C57.6109 23.3097 54.7546 16.4139 49.6702 11.3296C44.5859 6.24526 37.6901 3.38892 30.4998 3.38892ZM30.4998 54.2223C25.808 54.2223 21.2215 52.831 17.3204 50.2243C13.4193 47.6177 10.3788 43.9128 8.58332 39.5781C6.78784 35.2435 6.31806 30.4737 7.23339 25.8721C8.14872 21.2704 10.408 17.0435 13.7257 13.7259C17.0433 10.4083 21.2702 8.14895 25.8718 7.23362C30.4735 6.31829 35.2432 6.78807 39.5779 8.58355C43.9126 10.379 47.6175 13.4196 50.2241 17.3207C52.8307 21.2218 54.222 25.8082 54.222 30.5C54.222 36.7916 51.7227 42.8254 47.2739 47.2742C42.8252 51.723 36.7913 54.2223 30.4998 54.2223Z" fill="#61C100" />
        <path d="M47.4448 20.5027C47.1273 20.1872 46.6979 20.01 46.2502 20.01C45.8026 20.01 45.3731 20.1872 45.0556 20.5027L26.2473 39.2264L16.0806 29.0597C15.7705 28.7249 15.3402 28.527 14.8842 28.5095C14.4282 28.492 13.9839 28.6564 13.6491 28.9665C13.3143 29.2766 13.1164 29.707 13.0989 30.163C13.0814 30.619 13.2458 31.0632 13.5559 31.398L26.2473 44.0555L47.4448 22.9089C47.6036 22.7513 47.7297 22.5639 47.8157 22.3574C47.9017 22.151 47.946 21.9295 47.946 21.7058C47.946 21.4821 47.9017 21.2606 47.8157 21.0542C47.7297 20.8477 47.6036 20.6603 47.4448 20.5027Z" fill="#61C100" />
      </g>
      <defs>
        <clipPath id="clip0_7462_7894">
          <rect width="61" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
