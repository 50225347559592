const baseUrl = process.env.BACKEND_URL;

const requests = {
  makeTransfer: {
    url: `${baseUrl}/employee/transfer/card`,
    errorMessages: {
      403: 'Session expired, please reload',
      500: 'Could not complete your transfer at the moment. Try again later',
    },
    method: 'POST',
  },
  validateSession: {
    url: `${baseUrl}/employee/auth/access_token/session`,
    errorMessage: "Invalid session",
    method: 'GET',
  },
  getConfig: {
    url: `${baseUrl}/config`,
    errorMessage: "Could not load configuration, please retry",
    method: 'GET',
  },
  getBeneficiaries: {
    url: `${baseUrl}/employee/transfer/beneficiary/instant`,
    errorMessages: {
      403: 'Session expired, please reload',
      500: 'An error occured',
    },
    method: 'GET',
  },
  deleteBeneficiary: {
    url: `${baseUrl}/employee/transfer/beneficiary/%{}%`,
    errorMessages: {
      403: 'Session expired, please reload',
      500: 'Could not fetch your saved cards at the moment. Try again later',
    },
    method: 'DELETE',
  },
  getProfile: {
    url: `${baseUrl}/employee/profile`,
    errorMessage: "Could not fetch your profile at the moment. Try again later",
    method: 'GET',
  },
  saveBeneficiary: {
    url: `${baseUrl}/employee/transfer/beneficiary/instant`,
    errorMessages: {
      403: 'Session expired, please reload',
      500: "Could not save your card at the moment. Try again later",
    },
    method: 'POST',
  },
}

export default async function request(url, reqBody, token, cb) {
  const arr = url.split(":");
  try {
    const reqDetails = requests[arr[0]];
    if (!reqDetails) return cb(new Error("Invalid request"));

    let endpoint = reqDetails.url;
    for(const param of arr.slice(1)) {
      endpoint = endpoint.replace("%{}%", param);
    }
    const res = await fetch(endpoint, {
      method: reqDetails.method,
      body: reqBody && reqDetails.method.toLowerCase() !== "get" ? JSON.stringify(reqBody) : null,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });

    const body = await res.json();

    if (Math.floor(res.status / 100) != 2) {
      return cb(new Error((reqDetails.errorMessages || {})[res.status] || reqDetails.errorMessage || body.message));
    }

    cb(null, body);
  } catch (error) {
    console.log(error);
    cb(new Error("An error occured"));
  }
}