/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Card = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 23C12 19.229 12 17.343 13.172 16.172C14.343 15 16.229 15 20 15H24C27.771 15 29.657 15 30.828 16.172C32 17.343 32 19.229 32 23C32 26.771 32 28.657 30.828 29.828C29.657 31 27.771 31 24 31H20C16.229 31 14.343 31 13.172 29.828C12 28.657 12 26.771 12 23Z" stroke="#B2B2B2" strokeWidth="1.5" />
      <path opacity="0.5" d="M20 27.5H16M18 24.5H16M12 21H32" stroke="#B2B2B2" strokeWidth="1.5" strokeLinecap="round" />
      <path opacity="0.5" d="M24 26C24 25.057 24 24.586 24.293 24.293C24.586 24 25.057 24 26 24C26.943 24 27.414 24 27.707 24.293C28 24.586 28 25.057 28 26C28 26.943 28 27.414 27.707 27.707C27.414 28 26.943 28 26 28C25.057 28 24.586 28 24.293 27.707C24 27.414 24 26.943 24 26Z" stroke="#B2B2B2" strokeWidth="1.5" />
    </svg>
  );
};
