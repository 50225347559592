import React from "react";
import "./style.css";
import { TypePrimaryState } from "../TypePrimaryState";

export const Modal = ({ show, children, closeFn, backdropClose }) => {

  return (
    <>
      {
        show && <div className="modal">
          <div className="backdrop" onClick={e => backdropClose && e.target.className == "backdrop" ? closeFn() : null}>
            <div className="content">
              <div className="body">
                {children}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
