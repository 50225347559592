import React, { useEffect, useState } from "react";
import "./style.css";
import { ChevronLeft1 } from "../../icons/ChevronLeft1";
import { Delete } from "../../icons/Delete";

export const BeneficiaryList = ({ beneficiaries, setBeneficiaryOpen, setBeneficiary, removeBeneficiary }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [optionOpen, setOptionOpen] = useState({});

  const getInitials = name => {
    if (!name) return '#';
    name = name.split(" ");
    return `${name[0].slice(0, 1)}${(name[1] || '').slice(0, 1)}`.toUpperCase();
  }

  const closeBeneficiaryScreen = () => {
    setOptionOpen({});
    setBeneficiaryOpen(false)
  }

  const selectBeneficiary = (selected, e) => {
    if (e.target.className != "option") {
      if (optionOpen[selected.id]) {
        return clickOption(selected.id);
      }
      setBeneficiary(selected);
      setOptionOpen({});
      setBeneficiaryOpen(false);
    }
  }

  const clickOption = selected => {
    const newOpened = { ...optionOpen };
    if (optionOpen[selected]) delete newOpened[selected];
    else newOpened[selected] = true;
    setOptionOpen(newOpened);
  }

  return (
    <div>
      <div className="beneficiary-screen-header">
        <span className="beneficiary-screen-back" onClick={closeBeneficiaryScreen}><ChevronLeft1 /></span>
        <span className="beneficiary-screen-title">Saved Cards</span>
      </div>

      <div className="beneficiary-screen-search">
        <input type="search" value={searchTerm} placeholder="Search" onChange={e => setSearchTerm(e.target.value)} />
      </div>

      <div className="beneficiary-screen-list">
        {
          beneficiaries
            .filter(p => (p.name || '').toLowerCase().includes(searchTerm.toLowerCase()) || p.expiryDate.includes(searchTerm))
            .map((b, i) => (
              <div key={b.id}>
                <div className="beneficiary-screen-item">
                  <div className={"beneficiary-screen-container" + (optionOpen[b.id] ? " translate" : "")}>
                    <div className="beneficiary-screen-item-inner" onClick={(e) => selectBeneficiary(b, e)}>
                      <div className="beneficiary-avatar">
                        <div className={"beneficiary-avatar-bg" + ((i + 1) % 3 == 1 ? " blue" : (i + 1) % 3 == 2 ? " green" : " red")}>
                          <div className="beneficiary-avatar-initials">{getInitials(b.name)}</div>
                        </div>
                      </div>
                      <div className="beneficiary-screen-item-details">
                        <div className="beneficiary-screen-item-name">{b.name}</div>
                        <div className="beneficiary-screen-item-highlight">
                          <div className="beneficiary-screen-item-key">Card Number:</div>
                          <div className="beneficiary-screen-item-value">{b.cardNumber}</div>
                        </div>
                        <div className="beneficiary-screen-item-highlight">
                          <div className="beneficiary-screen-item-key">Expiration Date:</div>
                          <div className="beneficiary-screen-item-value">{b.expiryDate}</div>
                        </div>
                      </div>
                      <div className="option" onClick={() => clickOption(b.id)}>
                        &hellip;
                      </div>
                    </div>
                    <div className="delete">
                      <div className="delete-icon-bg" onClick={() => removeBeneficiary(b.id)}>
                        <Delete />
                      </div>
                    </div>
                  </div>
                  <div className="beneficiary-screen-item-divider"></div>
                </div>
              </div>
            ))
        }
      </div>


    </div>
  );
};
