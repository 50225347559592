/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { BaseInput } from "../BaseInput";
import "./style.css";

export const InputField = ({
  label = "Label",
  info = "Note",
  showLabel = true,
  showInfo = true,
  state,
  className,
  divClassName,
  baseInputBaseInputClassName,
  baseInputPlaceholder = "Placeholder",
  baseInputId,
  value,
  setValue,
  isValid,
  inputRef,
  rightComponent,
  readOnly,
}) => {
  return (
    <div className={`input-field ${className}`}>
      {showLabel && <div className={`text-wrapper ${state} ${divClassName}`}>{label}</div>}
      <div className="frame-3">
        <BaseInput
          id={baseInputId}
          className={baseInputBaseInputClassName}
          frameClassName={"base-input-instance"  + (value? (' ' + (isValid? 'success' : 'error')): '')}
          placeholder={baseInputPlaceholder}
          value={value}
          setValue={setValue}
          inputRef={inputRef}
          rightComponent={rightComponent}
          readOnly={readOnly}
        />
        {showInfo && <div className="note">{info}</div>}
      </div>
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  showLabel: PropTypes.bool,
  showInfo: PropTypes.bool,
  state: PropTypes.oneOf(["warning", "active", "success", "normal", "hover", "alert", "disabled"]),
  baseInputPlaceholder: PropTypes.string,
};
