import request from "./request";

export const getConfig = async (cb) => {
  try {
    request("getConfig", null, null, cb);
  } catch (error) {
    console.log(error);
  }
}

export const getBeneficiaries = async (token, cb) => {
  request("getBeneficiaries", null, token, cb);
}

export const saveBeneficiary = async (token, data, cb) => {
  request("saveBeneficiary", data, token, cb);
}

export const deleteBeneficiary = async (token, id, cb) => {
  request(`deleteBeneficiary:${id}`, null, token, cb);
}

export const getProfile = async (token, cb) => {
  request("getProfile", null, token, cb);
}
