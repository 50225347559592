/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft1 } from "../../icons/ChevronLeft1";
import { ChevronRight1 } from "../../icons/ChevronRight1";
import "./style.css";

export const BaseButton = ({
  showText = true,
  showRightIcon = true,
  showLeftIcon = true,
  text = "Button text",
  className,
  divClassName,
}) => {
  return (
    <div className={`base-button ${className}`}>
      {showLeftIcon && <ChevronLeft1 className="icon-instance-node" />}

      {
        showText ?
          (<div className={`button-text ${divClassName}`}>{text}</div>) :
          <span className="loader small"></span>
      }

      {showRightIcon && <ChevronRight1 className="icon-instance-node" />}
    </div>
  );
};

BaseButton.propTypes = {
  showText: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  text: PropTypes.string,
};
