import React, { useState } from "react";
import "./style.css";
import { CheckCircle } from "../../icons/CheckCircle";
import { Modal } from "../Modal";
import { InputField } from "../InputField";

export const SuccessModal = ({
  show,
  closeFn,
  beneficiaryLoading,
  saveBeneficiary,
  beneficiaryName,
  setBeneficiaryName,
  saveBeneficiaryTxt,
  amount,
  beneficary,
}) => {
  const isBeneficiaryNameValid = (name) => {
    if (name.length >= 3 && name.length <= 50) return true;
    return false;
  }

  const closeModal = () => {
    closeFn();
  }

  return (
    <Modal
      closeFn={closeModal}
      backdropClose={true}
      show={show}
    >
      <div className="success-modal-cover">
        <div className="success-modal">
          <div className="header-bg">
            <div className="icon"><CheckCircle className="check-icon" /></div>
          </div>
          <div className="message">
            <div className="header">Withdrawal Request Successful</div>
            <div className="text">
              Your withdrawal request of {amount} was successful and is being processed
            </div>

          </div>
        </div>
        <div className="action">
          <div className="text">
            Would you like to save this card?
          </div>
          <InputField
            readOnly={saveBeneficiaryTxt.toLowerCase() == 'saved'}
            baseInputBaseInputClassName="beneficiary-name"
            baseInputPlaceholder="Name this card"
            baseInputValidation={false}
            className="input-field-instance"
            divClassName="design-component-instance-node"
            label=""
            showInfo={false}
            state="normal"
            value={beneficary?.name || beneficiaryName}
            setValue={setBeneficiaryName}
            isValid={isBeneficiaryNameValid}
            rightComponent={

              <div className="save-btn" onClick={saveBeneficiary}>
                {
                  beneficiaryLoading ?
                    <span className="loader small"></span> :
                    saveBeneficiaryTxt
                }
              </div>
            }
          />
        </div>
      </div>
    </Modal>

  );
};
