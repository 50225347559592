import React, { useState } from "react";
import "./style.css";
import { CheckCircle } from "../../icons/CheckCircle";
import { TypePrimaryState } from "../TypePrimaryState";
import { Modal } from "../Modal";

export const DeductionAuthorizationModal = ({ show, loading, acceptFn, closeFn, profile }) => {
  const [allow, setAllow] = useState(false);
  
  const closeModal = () => {
    setAllow(false);
    closeFn();
  }

  return (
    <Modal
      closeFn={closeModal}
      backdropClose={true}
      show={show}
    >
      <div className="authorization-cover">
        <div className="authorization">
          <div>
            <div className="check-icon"><CheckCircle /></div>
            <div className="header">Deduction Authorization</div>
          </div>
          <div className="message">
            I authorize my employer,
            <strong> {profile?.organization} </strong>
            to make the following payroll deduction(s) for the
            On demand Wages that I am receiving
            with this transfer. In the event my
            employment ends for any reason
            before the final deduction is made,
            I authorize for the entire balance to be
            deducted from my final wages.
          </div>
          <div className="agree">
            <input type="checkbox" onChange={e => setAllow(e.target.checked)} />
            I agree to the terms and conditions
          </div>
        </div>
        <div className="action">
          <TypePrimaryState
            baseButtonDivClassName="type-primary-state-normal-outline-false-link-false"
            baseButtonText={"Continue"}
            className="button"
            onClick={acceptFn || null}
            disabled={!allow || loading}
            loading={!!loading}
            baseButtonShowRightIcon={false}
            baseButtonShowLeftIcon={false}
          />

          <TypePrimaryState
            baseButtonDivClassName="type-primary-state-normal-outline-false-link-false"
            baseButtonText={"Cancel"}
            className="button"
            onClick={closeModal || null}
            disabled={loading}
            baseButtonShowRightIcon={false}
            baseButtonShowLeftIcon={false}
            type="outline"
          />
        </div>
      </div>
    </Modal>

  );
};
