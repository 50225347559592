import React, { useState } from "react";
import "./style.css";
import { CheckCircle } from "../../icons/CheckCircle";
import { TypePrimaryState } from "../TypePrimaryState";
import { Modal } from "../Modal";

export const FinLiteracyModal = ({ show, acceptFn, closeFn }) => {

  const closeModal = () => {
    closeFn();
  }

  return (
    <Modal
      closeFn={closeModal}
      backdropClose={true}
      show={show}
    >
      <div className="fin-literacy-cover">
        <div className="fin-literacy">
          <div className="header-bg">
            <div className="icon"><CheckCircle className="check-icon" /></div>
          </div>
          <div className="message">
            <div className="header">Transaction Successful</div>
            <div className="text">
              Thank you for using
              <strong> EXPRESS WAGES </strong> app,
              kindly visit our blog for Financial Literacy Information that may be useful
            </div>

          </div>
        </div>
        <div className="action">
          <TypePrimaryState
            baseButtonDivClassName="type-primary-state-normal-outline-false-link-false"
            baseButtonText={"Continue"}
            className="button"
            onClick={acceptFn || null}
            // disabled={!allow || loading}
            // loading={!!loading}
            baseButtonShowRightIcon={false}
            baseButtonShowLeftIcon={false}
          />
        </div>
      </div>
    </Modal>

  );
};
