/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const BaseInput = ({
  id,
  placeholder = "Placeholder",
  className,
  frameClassName,
  value,
  setValue,
  inputRef,
  rightComponent,
  readOnly,
}) => {
  return (
    <div className={`base-input ${className}`} id={id}>
      {
        !id && (
          <>
            <input
              type="text"
              className={`div ${frameClassName}`}
              placeholder={placeholder}
              onChange={e => setValue(e.target.value)}
              value={value || ''}
              ref={inputRef}
              readOnly={readOnly}
            />
            {rightComponent}
          </>
        )
      }

    </div>
  );
};
