/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChevronRight1 = ({ className }) => {
  return (
    <svg
      className={`chevron-right-1 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M4.64645 2.14645C4.84171 1.95118 5.15829 1.95118 5.35355 2.14645L11.3536 8.14645C11.5488 8.34171 11.5488 8.65829 11.3536 8.85355L5.35355 14.8536C5.15829 15.0488 4.84171 15.0488 4.64645 14.8536C4.45118 14.6583 4.45118 14.3417 4.64645 14.1464L10.2929 8.5L4.64645 2.85355C4.45118 2.65829 4.45118 2.34171 4.64645 2.14645Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
