import React from "react";
import "./style.css";
import { Card } from "../../icons/Card";

export const Beneficiaries = ({ beneficiaries, beneficiary, setBeneficiary, setBeneficiaryOpen }) => {

  const getInitials = name => {
    if (!name) return '#';
    name = name.split(" ");
    return `${name[0].slice(0, 1)}${(name[1] || '').slice(0, 1)}`.toUpperCase();
  }

  const selectBeneficiary = selected => {
    if (selected.id == beneficiary?.id) return setBeneficiary(null);
    setBeneficiary(selected);
  }

  return (
    <div className="beneficiary">
      <div className="beneficiary-header">
        <div className="beneficiary-header-text">Saved Cards</div>
        {beneficiaries.length > 0 && (
          <div className="beneficiary-header-view-all" onClick={() => setBeneficiaryOpen(true)}>view all</div>
        )}
      </div>

      <div className="beneficiary-list">
        {
          beneficiaries && beneficiaries.length > 0 ? beneficiaries.map(b => (
            <div
              className={"beneficiary-item" + (beneficiary?.id == b.id ? " active" : "")}
              key={b.id}
              onClick={() => selectBeneficiary(b)}
            >
              <div className="beneficiary-avatar">
                <div className="beneficiary-avatar-bg">
                  <div className="beneficiary-avatar-initials">{getInitials(b.name)}</div>
                </div>
              </div>
              <div className="beneficiary-title">{b.name}</div>
            </div>
          ))
         : (
          <div className="beneficiary-title">No saved cards</div>
        )
      }
      </div>

      {
        beneficiary && (
          <div className="beneficiary-info-wrapper">
            <div className="beneficiary-info-item">
              <div className="beneficiary-info-item-name">
                <span>Beneficiary Information </span>
                <span className="beneficiary-info-item-cancel" onClick={() => setBeneficiary(null)}>cancel</span>
              </div>
              <div className="beneficiary-info-item-inner">
                <div className="beneficiary-info-icon">
                  <Card />
                </div>
                <div className="beneficiary-info-item-details">
                  <div className="beneficiary-info-item-highlight">
                    <div className="beneficiary-info-item-key">Card Name:</div>
                    <div className="beneficiary-info-item-value">{beneficiary?.name}</div>
                  </div>
                  <div className="beneficiary-info-item-highlight">
                    <div className="beneficiary-info-item-key">Expiration Date:</div>
                    <div className="beneficiary-info-item-value">{beneficiary?.expiryDate}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};
