import request from "./request";

export const rawTransfer = async (amount, cardNumber, expiryDate, token, cb) => {
  try {
    const transferBody = {
      amountCents: Math.round(amount * 100), // Remove issues with floats
      cardNumber,
      expiryDate,
    }

    await request("makeTransfer", transferBody, token, cb);
  } catch (error) {
    console.error(error);
  }
}

export const beneficiaryTransfer = async (amount, beneficiary, token, cb) => {
  const transferBody = {
    amountCents: Math.round(amount * 100), // Remove issues with floats
    beneficiary,
  }

  await request("makeTransfer", transferBody, token, cb);
}

export const validateSession = (token, cb) => {
  request("validateSession", null, token, cb);
}
