/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { BaseButton } from "../BaseButton";
import "./style.css";

export const TypePrimaryState = ({
  className,
  baseButtonShowLeftIcon,
  baseButtonDivClassName,
  baseButtonShowRightIcon,
  baseButtonText = "Button text",
  onClick,
  disabled,
  loading,
  type = "filled",
}) => {

  return (
    <div
      className={`type-primary-state ${className}${disabled?' disabled': ''} ${type}`}
      onClick={disabled ? null : onClick}
    >
      <BaseButton
        className="base-button-instance"
        divClassName={baseButtonDivClassName}
        showLeftIcon={baseButtonShowLeftIcon}
        showRightIcon={baseButtonShowRightIcon}
        text={baseButtonText}
        showText={!loading}
      />
    </div>
  );
};

TypePrimaryState.propTypes = {
  baseButtonShowLeftIcon: PropTypes.bool,
  baseButtonShowRightIcon: PropTypes.bool,
  baseButtonText: PropTypes.string,
};
